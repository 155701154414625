<template>
  <vx-card title="Calculadora préstamo vehicular">
    <div class="flex justify-end mb-5">
      <vs-button
        size="small"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="addCarCondition()"
      >
        Agregar condición de vehículo
      </vs-button>
    </div>

    <form data-vv-scope="car-loan-data" ref="form" @submit.prevent class="grid gap-8 md:grid-cols-2 grid-cols-1">
      <vx-card
        v-for="(carCondition, index) in carConditions"
        :beforeChange="validateCarCondition"
        :title="`${index + 1} - ${carCondition.name}`"
      >
        <div>
          <div class="vx-col md:w-2/3 w-full pr-3">
            <label :for="`name${index}`">Nombre</label>
            <vs-input
              :id="`name${index}`"
              :name="`name-${index}`"
              v-model="carCondition.name"
              class="w-full"
              v-validate="'required'"
              autocomplete="off"
            />
            <span class="text-danger">{{ errors.first(`car-loan-data.name-${index}`) }}</span>
            <br />
          </div>

          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>Rango de años</label>
            </div>

            <div class="vx-col md:w-1/3 w-full">
              <label for="publish_at" class="w-full select-large">Inicio</label>
              <flat-pickr
                :config="yearPickerConfig"
                id="startDate" name="start-date" placeholder="Año de inicio"
                v-model="carCondition.startDate"
                v-validate="'required'"
                class="w-full flatpickr flatpickr-input"
              />
            </div>

            <div class="vx-col md:w-1/3 w-full">
              <label for="publish_at" class="w-full select-large">Fin</label>
              <flat-pickr
                :config="yearPickerConfig"
                id="endDate" name="end-date" placeholder="Año de inicio"
                v-model="carCondition.endDate"
                v-validate="'required'"
                class="w-full flatpickr flatpickr-input"
              />
            </div>
          </div>

          <br>

          <div class="vx-col w-2/5">
            <label :for="`rate${index}`" class="w-full select-large">Porcentaje mínimo de prima</label>
            <vs-input
              icon-pack="feather"
              icon="icon-percent"
              icon-after="true"
              :id="`premium-rate-${index}`"
              :name="`premium-rate-${index}`"
              v-model="carCondition.premium_settings.min_percentage"
              class="w-full"
              v-validate="'required|decimal:2|min_value:1|max_value:100'"
              autocomplete="off"
            />
            <span class="text-danger">{{ errors.first(`car-loan-data.premium-rate-${index}`) }}</span>
            <br />
          </div>

          <div class="vx-col w-2/5">
            <label :for="`rate${index}`" class="w-full select-large">Tasa</label>
            <vs-input
                icon-pack="feather"
                icon="icon-percent"
                icon-after="true"
                :id="`rate${index}`"
                :name="`rate-${index}`"
                v-model="carCondition.rate"
                class="w-full"
                v-validate="'required|decimal:2|min_value:1|max_value:100'"
                autocomplete="off"
            />
            <span class="text-danger">{{ errors.first(`car-loan-data.rate-${index}`) }}</span>
            <br />
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <label :for="`termDataInterval${index}`">Plazos disponibles</label>
            </div>

            <div class="vx-col md:w-1/5 w-full md:pr-0">
              <vs-select
                :id="`term${index}`"
                :name="`term-${index}`"
                v-model="carCondition.term_data.type"
                v-validate="'required'"
                class="w-full select-large"
              >
                <vs-select-item :key="false" value="months" :text="'Meses'" class="w-full" />
                <vs-select-item :key="true" value="years" :text="'Años'" class="w-full" />
              </vs-select>
              <span class="text-danger">{{ errors.first(`car-loan-data.term-${index}`) }}</span>
              <br />
            </div>

            <div class="vx-col md:w-2/5 w-full md:pr-0">
              <vs-input
                :id="`termDataInterval${index}`"
                :name="`term-data-interval-${index}`"
                v-model="carCondition.term_data.intervals"
                class="w-full"
                v-validate="'required|termDataInterval'"
                autocomplete="off"
              />
              <span class="text-danger">{{ errors.first(`car-loan-data.term-data-interval-${index}`) }}</span>
              <br />
            </div>
          </div>

          <div class="vx-row">
            <vs-divider class="vx-col w-full mt-5" position="center" color="dark">Datos seguro de daños</vs-divider>
            <div class="vx-col w-full pb-12">
              <div class="vx-col w-full">
                <div class="vx-col w-2/5">
                  <label :for="`rate${index}`" class="w-full select-large">Tasa por defecto</label>
                  <vs-input
                    icon-pack="feather"
                    icon="icon-percent"
                    icon-after="true"
                    :id="`damage-default-rate-${index}`"
                    :name="`damage-default-rate-${index}`"
                    v-model="carCondition.insurances_settings.damage.default.rate"
                    class="w-full"
                    v-validate="'required|decimal:2|min_value:1|max_value:100'"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ errors.first(`car-loan-data.damage-default-rate-${index}`) }}</span>
                  <br />
                </div>
              </div>

              <template v-if="carCondition.insurances_settings.damage.rates_by_currency.length > 0">
                <div v-for="(rate, rateIndex) in carCondition.insurances_settings.damage.rates_by_currency[0].rates" class="vx-col justify-end w-full">
                  <div class="vx-row">
                    <div class="vx-col w-11/12">
                      <div class="vx-row">
                        <div class="vx-col w-1/3">
                          <label :for="`rate-${index}-${rateIndex}`">Tasa</label>
                          <vs-input
                              icon-pack="feather"
                              icon="icon-percent"
                              icon-after="true"
                              :id="`damage-rate-${index}-${rateIndex}`"
                              :name="`damage-rate-${index}-${rateIndex}`"
                              v-model="rate.rate"
                              class="w-full"
                              v-validate="
                            `required|decimal:2|min_value:0|max_value:100|uniqueRates:${ratesArray(rateIndex, carCondition.insurances_settings.damage.rates_by_currency[0].rates)}`
                          "
                              autocomplete="off"
                          />
                          <span class="text-danger">{{ errors.first(`car-loan-data.damage-rate-${index}-${rateIndex}`) }}</span>
                          <br />
                        </div>

                        <div class="vx-col w-1/3">
                          <label for="category" class="w-full select-large">Zona</label>
                          <v-select
                              :id="`vehicle-features-location-${index}-${rateIndex}`"
                              :name="`vehicle-features-location-${index}-${rateIndex}`"
                              v-model="rate.conditions['vehicle_features'].location"
                              index="id"
                              :options="locations"
                              :placeholder="'Seleccione..'"
                              :searchable="true"
                              v-validate="'required'"
                              class="w-full"
                          />
                          <span class="text-danger">{{ errors.first(`car-loan-data.vehicle-features-location-${index}-${rateIndex}`) }}</span>
                          <br/>
                        </div>

                        <div class="vx-col w-1/3">
                          <label for="category" class="w-full select-large">Tipo</label>
                          <v-select
                              :id="`vehicle-features-type-${index}-${rateIndex}`"
                              :name="`vehicle-features-type-${index}-${rateIndex}`"
                              v-model="rate.conditions['vehicle_features'].type"
                              index="id"
                              :options="vehicleTypes"
                              :placeholder="'Seleccione..'"
                              :searchable="true"
                              v-validate="'required'"
                              class="w-full"
                          />
                          <span class="text-danger">{{ errors.first(`car-loan-data.vehicle-features-type-${index}-${rateIndex}`) }}</span>
                          <br/>
                        </div>
                      </div>
                    </div>

                    <div
                        v-if="carCondition.insurances_settings.damage.rates_by_currency[0].rates.length > 1"
                        class="vx-col w-1/12 flex justify-start self-center pl-0"
                    >
                      <vs-button
                          size="medium"
                          color="danger"
                          type="border"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click="removeRate(carCondition.insurances_settings.damage.rates_by_currency[0].rates, rateIndex)"
                      ></vs-button>
                    </div>
                  </div>
                </div>
              </template>

              <div class="vx-col flex justify-end">
                <vs-button
                    size="small"
                    color="success"
                    type="border"
                    icon-pack="feather"
                    icon="icon-plus"
                    @click="addRate(carCondition.insurances_settings.damage.rates_by_currency[0].rates, 'vehicle_features')"
                >
                  Agregar condición
                </vs-button>
              </div>
            </div>
          </div>

          <div class="vx-row">
            <vs-divider class="vx-col w-full mt-5" position="center" color="dark">Datos seguro de deudas</vs-divider>

            <div class="vx-col w-full">
              <div class="vx-col w-2/5">
                <label :for="`rate${index}`" class="w-full select-large">Tasa</label>
                <vs-input
                  icon-pack="feather"
                  icon="icon-percent"
                  icon-after="true"
                  :id="`debt-rate-${index}`"
                  :name="`debt-rate-${index}`"
                  v-model="carCondition.insurances_settings.debt.default.rate"
                  class="w-full"
                  v-validate="'required|decimal:2|min_value:1|max_value:100'"
                  autocomplete="off"
                />
                <span class="text-danger">{{ errors.first(`car-loan-data.debt-rate-${index}`) }}</span>
                <br />
              </div>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full pb-12">
              <vs-divider class="vx-col w-full mt-5" position="center" color="dark">Gastos de cierre</vs-divider>

              <div v-for="(closeExpense, closeExpenseIndex) in carCondition.close_expenses" class="vx-col justify-end w-full">
                <div class="vx-row">
                  <div class="vx-col w-11/12">
                    <div class="vx-row">
                      <div class="vx-col w-1/2">
                        <label :for="`rate-${index}-${closeExpenseIndex}`">Concepto</label>
                        <vs-input
                          :id="`close-expense-concept-${index}-${closeExpenseIndex}`"
                          :name="`close-expense-concept-${index}-${closeExpenseIndex}`"
                          v-model="closeExpense.concept"
                          class="w-full"
                          v-validate="`required`"
                          autocomplete="off"
                        />
                        <span class="text-danger">{{ errors.first(`car-loan-data.close-expense-concept-${index}-${closeExpenseIndex}`) }}</span>
                        <br />
                      </div>

                      <div class="vx-col w-1/3">
                        <label for="category" class="w-full select-large">valor</label>
                        <vs-input
                          :id="`close-expense-value-${index}-${closeExpenseIndex}`"
                          :name="`close-expense-value-${index}-${closeExpenseIndex}`"
                          v-model="closeExpense.value"
                          v-validate="'required|decimal|min_value:1'"
                          class="w-full"
                          autocomplete="off"
                        />
                        <span class="text-danger">{{ errors.first(`car-loan-data.close-expense-value-${index}-${closeExpenseIndex}`) }}</span>
                        <br/>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="carCondition.close_expenses.length > 1"
                    class="vx-col w-1/12 flex justify-start self-center pl-0"
                  >
                    <vs-button
                      size="medium"
                      color="danger"
                      type="border"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click="removeCloseExpense(carCondition.close_expenses, closeExpenseIndex)"
                    ></vs-button>
                  </div>
                </div>
              </div>

              <div class="vx-col flex justify-end">
                <vs-button
                  size="small"
                  color="success"
                  type="border"
                  icon-pack="feather"
                  icon="icon-plus"
                  @click="addCloseExpense(carCondition.close_expenses)"
                >
                  Agregar gasto de cierre
                </vs-button>
              </div>
            </div>
          </div>
        </div>

        <div slot="footer" class="absolute bottom-0 right-0 mt-6 pb-6 pr-6">
          <vs-row vs-justify="flex-end">
            <vs-button
              v-if="carConditions.length > 1"
              radius
              size="medium"
              color="danger"
              type="border"
              icon-pack="feather"
              icon="icon-trash"
              @click="removeCondition(index)"
            ></vs-button>
          </vs-row>
        </div>
      </vx-card>
    </form>

    <div class="flex justify-end mt-5">
      <vs-button
        v-if="$can('update', 'calculator')"
        size="small"
        icon-pack="feather"
        icon="icon-save"
        @click="updateCalculator()"
        class="ml-4"
      >
        Guardar cambios
      </vs-button>
    </div>
  </vx-card>
</template>

<script>
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";

  import { Validator } from "vee-validate";

  import { termDataInterval, uniqueRates } from "@/utils/customValidations";

  import vSelect from "vue-select";
  import { cloneDeep } from "lodash";

  import Configurations from "@/utils/configurations";
  import CoreClient from "@/utils/coreClient";
  import Notifier from "@/utils/notification";

  Validator.localize({
    en: {
      messages: {
        required: "Este campo es requerido",
        decimal: (_, decimals) => `El campo debe ser numérico y puede contener hasta ${decimals} puntos decimales`,
        min_value: (_, min) => `El campo no debe ser menor a: ${min}`,
        max_value: (_, max) => `El campo no debe ser mayor a: ${max}`,
      },
    },
  });

  Validator.extend("termDataInterval", termDataInterval);
  Validator.extend("uniqueRates", uniqueRates);

  const configurations = new Configurations();
  const calculatorsClient = new CoreClient("calculators");
  let notifier = new Notifier();

  export default {
    name: "CUCalculator",

    components: {
      "v-select": vSelect,
      flatPickr,
    },

    data() {
      return {
        calculator: {},
        carConditions: [],
        yearPickerConfig: configurations.yearDatePicker(),
        vehicleTypes: [
          {
            "id": "pickUp",
            "label": "Pick-Up",
          },
          {
            "id": "sedan",
            "label": "Sedan",
          },
        ],
        locations: [
          {
            "id": "norte",
            "label": "Norte",
          },
          {
            "id": "centro",
            "label": "Centro",
          },
        ],
      };
    },

    mounted() {
      this.$vs.loading();

      this.getCalculator(false).then(() => {
        this.$vs.loading.close();
      });
    },

    methods: {
      addRate(rates, condition) {
        rates.push({
          rate: 0,
          conditions: {
            [condition]: {
              min: 0,
              max: null,
            },
          },
        });
      },

      addCloseExpense(closeExpenses) {
        closeExpenses.push({
          concept: null,
          value: null,
        });
      },

      buildData(conditions) {
        let data = [];
        for (const condition of conditions) {

          condition.term_data.intervals = [
            ...new Set(condition.term_data.intervals.split(",").map(Number)),
          ].sort((a, b) => a - b);

          let rates_by_currency = {
            "HNL": [
              {
                rate: Number(condition.rate),
                conditions: {},
              },
            ]
          };

          condition.rates_by_currency = rates_by_currency;
          let insurancesSettings = {};

          for (const insuranceType in condition.insurances_settings) {
            insurancesSettings[insuranceType] = {"rates_by_currency": {}};

            const insuranceTypeSettings = condition.insurances_settings[insuranceType];

            for (const rateByCurrency of insuranceTypeSettings.rates_by_currency) {
              insurancesSettings[insuranceType]["rates_by_currency"][rateByCurrency.currency] = [
                  insuranceTypeSettings.default
              ];

              if (rateByCurrency.rates.length < 1) {
                continue;
              }

              if (insurancesSettings[insuranceType]["rates_by_currency"][rateByCurrency.currency]) {
                insurancesSettings[insuranceType]["rates_by_currency"][rateByCurrency.currency] = insurancesSettings[insuranceType]["rates_by_currency"][rateByCurrency.currency].concat(rateByCurrency.rates)
              }
            }
          }

          const currentYear = new Date().getFullYear();

          const startYearDifference = new Date(condition.startDate).getFullYear() - currentYear;
          const endYearDifference = new Date(condition.endDate).getFullYear() - currentYear;

          let timeDelta = {
            start: {
              month: 0,
              year: startYearDifference,
              day: 0
            },
            end: {
              month: 0,
              year: endYearDifference,
              day: 0
            }
          };

          data.push({
            name: condition.name,
            rates_by_currency: condition.rates_by_currency,
            premium_settings: condition.premium_settings,
            insurances_settings: insurancesSettings,
            term_data: condition.term_data,
            close_expenses: condition.close_expenses,
            time_delta: timeDelta,
          });
        }

        return {
          name: this.calculator.name,
          settings: {
            conditions: data,
          },
        };
      },

      calculateDate(offset) {
        const currentDate = new Date();
        const year = currentDate.getFullYear() + offset.year;
        const month = currentDate.getMonth() + offset.month;
        const day = currentDate.getDate() + offset.day;

        const date = new Date(year, month, day);

        return date.toISOString().split('T')[0];
      },

      async updateCalculator() {
        this.validateCarCondition()
          .then(async (resp) => {
            if (resp === true) {
              this.$vs.loading();

              let data = this.buildData(cloneDeep(this.carConditions));

              await calculatorsClient.updateCalculator({
                pathParameters: { id: this.calculator.id },
                data: data,
              })
                .then(() => {
                  this.$vs.loading.close();
                  notifier.notification("created");
                  this.getCalculator();
                })
                .catch(() => {
                  this.$vs.loading.close();
                  notifier.alertMessage("error");
                });
            }
          })
          .catch(() => {
            return;
          });
      },

      async getCalculator(showLoading = true) {
        if (showLoading) {
          this.$vs.loading();
        }

        await calculatorsClient.readCalculator({ pathParameters: { id: this.$route.params.id } })
          .then((response) => {
            if (showLoading) {
              this.$vs.loading.close();
            }

            this.calculator = response.data;
            this.carConditions = this.calculator.settings.conditions

            this.prepareData();
          })
          .catch(() => {
            if (showLoading) {
              this.$vs.loading.close();
            }
            notifier.notification("error");
          });
      },

      removeRate(rates, index) {
        rates.splice(index, 1);
      },

      removeCloseExpense(closeExpenses, index) {
        closeExpenses.splice(index, 1);
      },

      removeCondition(index) {
        this.carConditions.splice(index, 1);
      },

      addCarCondition() {
        this.carConditions.push({
          name: "",
          premium_settings: {
            min_percentage: 0,
          },
          rates_by_currency: [
            {
              currency: "HNL",
              rates: [
                {
                  conditions: {},
                  rate: 0,
                },
              ],
            },
          ],
          term_data: {
            intervals: "1, 2, 3, 4, 5, 6",
            type: "years",
          },
          startDate: "2024",
          endDate: "2024",
          time_delta: {},
          insurances_settings: {
            damage: {
              default: {
                conditions: {},
                rate: 10
              },
              rates_by_currency: [
                {
                  currency: "HNL",
                  rates: []
                }
              ]
            },
            debt: {
              default: {
                conditions: {},
                rate: 10
              },
              rates_by_currency: [
                {
                  currency: "HNL",
                  rates: []
                }
              ]
            }
          },
          close_expenses: [],
        });

        var messageDisplay = this.$refs.form;

        setTimeout(() => {
          messageDisplay.scrollIntoView({ behavior: "smooth", block: "end" });
        }, 500);
      },

      prepareData() {
        for (const condition of this.carConditions) {
          condition.term_data.intervals = condition.term_data.intervals.join(", ");

          let ratesByCurrency = [];
          let insuranceSettings = {}

          for (const currency in condition.rates_by_currency) {
            ratesByCurrency.push({
              currency: currency,
              rates: condition.rates_by_currency[currency],
            });
          }

          for (const type in condition.insurances_settings) {
            insuranceSettings[type] = { "rates_by_currency": [] };
            let rates = [];

            for (const currency in condition.insurances_settings[type].rates_by_currency) {
              let conditionsByCurrency = condition.insurances_settings[type].rates_by_currency[currency];

              insuranceSettings[type]["default"] = conditionsByCurrency.filter((item) => Object.keys(item.conditions).length < 1)[0];
              let conditionalRates = conditionsByCurrency.filter((item) => Object.keys(item.conditions).length > 0)

              rates.push({
                currency: currency,
                rates: conditionalRates,
              });
            }

            insuranceSettings[type].rates_by_currency = rates;
          }

          this.$set(condition, "rates_by_currency", ratesByCurrency);
          this.$set(condition, "insurances_settings", insuranceSettings);
          this.$set(condition, "rate", condition.rates_by_currency[0].rates[0].rate);
          this.$set(condition, "currency", condition.rates_by_currency[0].currency);
          this.$set(condition, "startDate", this.calculateDate(condition.time_delta.start));
          this.$set(condition, "endDate", this.calculateDate(condition.time_delta.end));
        }
      },

      ratesArray(index, ratesData) {
        let rates = ratesData.map((rate) => rate.rate);

        rates.splice(index, 1);

        return rates;
      },

      validateCarCondition() {
        return new Promise((resolve, reject) => {
          this.$validator.validateAll("car-loan-data").then((result) => {
            if (result) {
              resolve(true);
            } else {
              reject("Please fix invalid fields");
            }
          });
        });
      },
    }
  }
</script>

<style scoped>

</style>